@media screen and (max-width: 1680px) {
    .myprojects {
        padding-top: 23% !important;
    }
    .homepage h2 {
        font-size: 32px;
        letter-spacing: 15px;
    }
    .homepage p {
        font-size: 22px;
    }
    .swiper-button-prev:after, .swiper-button-next:after {
        color: #fff;
        font-size: 28px !important;
        font-weight: 200 !IMPORTANT;
    }
    .row.partners h2 {
        font-size: 20px !important;
        padding-top: 15px;
    }
    .aboutpage h2 {
        font-size: 38px !important;
    }

     .row.services h3 {
        text-align: left;
        font-size: 17px;
        margin-bottom: 5px;
    }
    .partners .col-sm-2 {
        margin-bottom: 10px;
    }
    .container-fluid.logo {
        top: 3%;
    }
    .aboutpage p {
        font-size: 16px;
     }
     .container-fluid.footer {
        position: absolute;
        z-index: 999;
        bottom: 1% !important;
    }
    .image-gallery-thumbnails-wrapper {
        padding-top: 1%;
    }
    .nextslide {
        bottom: 9%;
    }
    .col-sm-12.myprojects {
        width: 85%;
        margin: auto;
    }
    .myprojects iframe {
        width: 1075px;
        height: 330px;
    }
    .image-gallery-slides img {
        height: 360px !important;
        object-fit: cover !important;
    }
    .partners h2 {
        padding-bottom: 0;
    }
    .row.services.desktop {
        padding-top: 0%;
    }
    .servicepage h2 {
        font-size: 38px;
    }
    textarea.ant-input {
        min-height: 130px !important;
    }
    .container-fluid.footer ul {
        gap: 8%;
    }
    .container-fluid.footer ul li{
        font-size: 14px;
    }
    .image-gallery-slides h2 {
        margin-top: -3.5% !important;
        font-size: 18px !important;
    }
    .container-fluid.footer ul {
        gap: 4%;
    }
    .image-gallery-slides video{
        height: 330px !important;
    }
    .image-gallery-swipe h2 {
        color: #fff;
        font-size: 24px;
        font-weight: 500;
        margin-top: -2.6%;
        position: relative;
      }
}

@media screen and (max-width: 1650px) {
    .myprojects {
        padding-top: 16% !important;
    }
    .homepage h2 {
        font-size: 28px;
        letter-spacing: 15px;
    }
    .homepage p {
        font-size: 19px;
        letter-spacing: 10px;
    }
    .swiper-button-prev:after, .swiper-button-next:after {
        color: #fff;
        font-size: 28px !important;
        font-weight: 200 !IMPORTANT;
    }
    .row.partners h2 {
        font-size: 20px !important;
        padding-top: 15px;
    }
    .aboutpage h2 {
        font-size: 38px !important;
    }
     .row.services h3 {
        text-align: left;
        font-size: 17px;
        margin-bottom: 5px;
    }
    .partners .col-sm-2 {
        margin-bottom: 10px;
    }
    .container-fluid.logo {
        top: 3%;
    }
    .aboutpage p {
        font-size: 16px;
     }
     .container-fluid.footer {
        position: absolute;
        z-index: 999;
        bottom: 1% !important;
    }
    .image-gallery-thumbnails-wrapper {
        padding-top: 1%;
    }
    .nextslide {
        bottom: 9%;
    }
    .col-sm-12.myprojects {
        width: 85%;
        margin: auto;
    }
    .myprojects iframe {
        width: 1075px;
        height: 330px;
    }
    .image-gallery-slides img {
        height: 360px !important;
        object-fit: cover !important;
    }
    .partners h2 {
        padding-bottom: 0;
    }
    .row.services.desktop {
        padding-top: 0%;
    }
    .servicepage h2 {
        font-size: 38px;
    }
    textarea.ant-input {
        min-height: 130px !important;
    }
    .container-fluid.footer ul {
        gap: 8%;
    }
    .container-fluid.footer ul li{
        font-size: 14px;
    }
    .image-gallery-slides h2 {
        margin-top: -3.5% !important;
        font-size: 18px !important;
    }
    .container-fluid.footer ul {
        gap: 4%;
    }
    .image-gallery-slides video{
        height: 330px !important;
    }
    .image-gallery-swipe h2 {
        color: #fff;
        font-size: 24px;
        font-weight: 500;
        margin-top: -2.6%;
        position: relative;
      }
}

@media screen and (max-width: 1450px) {
    .container-fluid.footer ul {
        gap: 2%;
    }
    .homepage h2 {
        font-size: 32px;
    }
    .homepage p {
        font-size: 22px;
    }
    .ant-form-item{
        margin-bottom: 15px !important;
    }
    .col-sm-12.myprojects {
        width: 80%;
        margin: auto;
    }
    
}

@media screen and (max-width: 1370px) {
    .myprojects iframe {
        width: 1050px !important;
    }
    .servicepage h2 {
        font-size: 34px !important;
        padding-top: 25px;
    }
    .aboutpage h2 {
        font-size: 34px !important;
     }
     .aboutpage p {
        width: 100% !important;
    }
    .myprojects iframe {
        width: 920px !important;
    }
    .myprojects {
        padding-top: 15% !important;
    }
    .container-fluid.footer ul {
        gap: 2%;
    }
    .aboutpage p {
        font-size: 14px;
    }
    .contactpage h2 {
        margin-bottom: 10px;
    }
    .image-gallery-slides img {
        height: 290px !important;
        object-fit: cover !important;
    }
    nav.image-gallery-thumbnails-container img {
        height: 50px;
    }
    .nextslide {
        bottom: 11%;
    }
    .image-gallery-slides h2 {
        margin-top: -4% !important;
        font-size: 18px !important;
    }
    .homepage h2 {
        font-size: 26px !important;
        letter-spacing: 15px !important;
    }
    .homepage p {
        font-size: 18px;
    }
    .row.services h3 {
        text-align: left;
        font-size: 15px;
        margin-bottom: 5px;
        padding-bottom: 10px;
    }
    .row.services h2 {
        padding-bottom: 1%;
    }
    .brands.row.desktop img {
        width: 110px !important;
        object-fit: contain;
        height: 70px;
    }
}  
  
@media screen and (max-width: 1290px) {   
    .aboutpage p {
        font-size: 16px !important;
    }
    .aboutpage h2 {
        font-size: 42px !important;
     }
     .myprojects {
        padding-top: 24% !important;
    }
    .aboutpage .col-sm-12 {
        width: 75%;
        background-color: #00000073;
        padding: 10px;
    }
    .image-gallery-slides img{
        height: 350px !important;
    }
}      
     
@media screen and (max-width: 820px) {
    .mobilemenu {
        display: block !important;
    }
    .container-fluid.footer {
        display: none;
    }
    .mobilemenu {
        position: absolute;
        top: 3%;
        z-index: 99999999;
        left: 0%;
    }
    button.btn.btn-primary {
        color: #000;
        background-color: #FFFFFF;
        border-color: #FFFFFF;
        border: unset !important;
        padding: 8px 30px;
    }
    .offcanvas.offcanvas-start.show {
        z-index: 9999999999;
        background: #000;
    }
    .btn-primary:focus{
        box-shadow: unset !important;
    }
    .offcanvas ul li {
        color: #fff !important;
        list-style: none;
        font-size: 22px !important;
        padding-bottom: 10%;
    }
    .offcanvas ul {
    padding-left: 0 !important; 
    }
    .offcanvas-title.h5 {
        color: #fff;
        font-weight: 900;
        font-size: 26px;
    }
    button.btn-close {
        color: #fff !important;
        filter: brightness(1) invert(1);
        opacity: 1;
    }
    .aboutpage .col-sm-12 {
        width: 95%;
        background-color: #00000073;
        padding: 10px;
    }
    .myprojects {
        padding-top: 36% !important;
    }
    .under .swiper-button-next {
        right: 6%;
        left: auto;
    }
    .row.services.desktop {
        display: none;
    }
    .mobilebrands {
        display: block;
    }
    .mobilebrands .swiper-slide {
        display: flex;
        align-items: center;
        height: 110px !important;
    }
    .brands.row.desktop {
        display: none;
    }
    .services .col-sm-2 {
        flex: 0 0 auto;
        width: 100%;
    }
    .brands .col-sm-2 {
        flex: 0 0 auto;
        width: 80%;
    }
    .services .col-sm-2 h3{
        text-align: center;
    }
    .swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal {
        top: 90px;
    }
    .container-fluid.aboutpage.contactpage .col-sm-6 {
        width: 75% !IMPORTANT;
    }
    .ant-btn {
        color: #fff !important;
    }
    .ant-btn span{
        color: #fff !important;
    }
    span.swiper-pagination-bullet {
    background: #929292 !IMPORTANT;
}
.ant-form-item-control-input-content {
    position: relative;
    z-index: 99999;
}
.ant-form-item-explain-error{
 color: red !important;
}
.swiper-slide {
    background-position: center !important;
}
.under .swiper-button-prev {
    left: 6%;
    right: auto;
}
.prevslide {
    left: 50% !important;
}
.prevslide {
    left: 50% !important;
}
}
@media screen and (max-width: 520px) {
    .container-fluid.footer {
        display: none;
    }
    .container-fluid.logo {
        top: 2% !important;
    }
    .under .swiper-button-next {
        right: 10% !important;
        left: unset !important;
        top: 72%;
    }
    .under .swiper-button-prev {
        left: 10%;
        right: auto;
        top: 72%;
    }
    .prevslide {
        position: absolute;
        z-index: 999;
        top: 17%;
        left: auto !important;
        background: transparent;
        border: unset;
        color: #fff;
        width: 100%;
        text-align: center;
    }
    .nextslide {   
        bottom: 4% !important;
        left: auto !important;
        width: 100%;
        text-align: center;
    }
    .swiper-button-next {
        left: 47% !important;
    }
    .homepage h2 {
        color: #fff;
        text-transform: uppercase ;
        font-size: 32px;
        letter-spacing: 10px;
        
    }
    .homepage p {
        color: #fff;
        text-transform: uppercase;
        font-size: 16px;
        letter-spacing: 7px;
        padding-top: 0px;
    }
    .aboutpage p {
        text-align: left;
        color: #fff;
        text-transform: capitalize;
        font-size: 14px !important;
        letter-spacing: 2px;
        width: 100% !important;
    }
    .container-fluid.aboutpage {
        padding-top: 0%;
    }
    .myprojects {
        padding-top: 35% !important;
    }
    .container {
        padding: 0 5px !important;
    }
    .myprojects iframe {
        width: 100% !important;
    }
    .image-gallery-swipe h2 {
        margin-top: 0% !important;
        margin-bottom: 20px !important;
        background: #000 !important;
    }
    .desktop{
        display: none !important;
    }
    .mobilebrands .swiper-slide {
        height: auto !important;
    }
    .servicepage h2 {
        font-size: 21px !important;
        padding-top: 24px;
        line-height: 35px;
    }
    .mobilebrands{
        display: block !important;
      }
      .servicepage h2 {
        font-size: 26px !important;
        padding-top: 24px;
        line-height: 35px;
        text-align: center;
    }
    .row.services h3 {
        text-align: left;
        font-size: 17px;
        margin-bottom: 15px;
        text-align: center;
        margin-top: 22px;
    }
    .swiper-slide {
        padding-bottom: 20px !important;
    }
    :root {
        --swiper-theme-color: #fff;
    }
    .brands.row.mobilebrands .swiper-slide {
        padding-bottom: 20% !IMPORTANT;
    }
    .row.partners h2 {
        font-size: 14px !IMPORTANT;
        letter-spacing: 8px !IMPORTANT;
        line-height: 20px;
    }
    span.swiper-pagination-bullet {
        background: #929292 !IMPORTANT;
    }
    .aboutpage.contactpage h2 {
        font-size: 32px !important;
        letter-spacing: 12px;
    }
    .row.preloader img {
        width: 55% !important;
    }
    button.btn.btn-primary {
        color: #000;
        background-color: #FFFFFF;
        border-color: #FFFFFF;
        border: unset !important;
        padding: 8px 30px;
    }
    .offcanvas.offcanvas-start.show {
        z-index: 9999999999;
        background: #000;
    }
    .btn-primary:focus{
        box-shadow: unset !important;
    }
    .offcanvas ul li {
        color: #fff !important;
        list-style: none;
        font-size: 22px !important;
        padding-bottom: 10%;
    }
    .offcanvas ul {
    padding-left: 0 !important; 
    }
    .offcanvas-title.h5 {
        color: #fff;
        font-weight: 900;
        font-size: 26px;
    }
    button.btn-close {
        color: #fff !important;
        filter: brightness(1) invert(1);
        opacity: 1;
    }
    .mobilemenu {
        position: absolute;
        top: 3%;
        z-index: 99999999;
        left: -5%;
    }
    .mobilemenu {
        display: block !important;
    }
    .mobilebrands .swiper-slide {
        display: flex;
        align-items: center;
        height: 110px !important;
    }
    /* .aboutpage p.react-reveal {
        height: 330px !IMPORTANT;
        overflow-y: scroll;
    } */
    .container-fluid.logo img {
        width: 28% !IMPORTANT;
    }
    .swiper-button-prev:after, .swiper-button-next:after {
        color: #fff;
        font-size: 22px !important;
        font-weight: 200 !IMPORTANT;
    }
    .aboutpage p {
        font-size: 11px !important;
        margin-bottom: 5px !important;
        width: 100% !important;
    }
    .aboutpage h2 {
        font-size: 30px !important;
        letter-spacing: 6px !important;
    }
    .col-sm-12.myprojects {
        width: 100%;
        margin: auto;
    }
    .image-gallery-slides img {
        height: 260px !important;
        object-fit: cover !important;
    }
    iframe{
        height: 260px !important;
    }
    .row.partners .swiper-slide img {
        display: block;
        width: 90%;
        height: 100%;
        object-fit: cover;
    }
    .image-gallery-swipe h2 {
        color: #fff;
        font-size: 18px;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .aboutpage .col-sm-12 {
        width: 100%;
        background-color: #00000073;
        padding: 10px;
    }
    .container-fluid.aboutpage.contactpage .col-sm-6 {
        width: 100% !IMPORTANT;
    }
    .ant-form-item-explain-error{
 color: red !important;
}
html, body {
    overflow-y: hidden !important;
}
.swiper-slide {
    height: 100% !important;
    overflow-y: hidden !important;
}
.aboutpage, .homepage {
    height: 100vh !important;
    overflow-y: hidden !important;
}
}

@media not all and (min-resolution: .001dpcm) { 
    @supports (-webkit-backdrop-filter: none) {
      body , .homepage {
        overflow-y: hidden !important;
      }
    }
  }