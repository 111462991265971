body {
  background-color: black;
}
p,
h1,
h2,
h3,
a,
span,
a,
div {
  font-family: "Montserrat", sans-serif !important;
}
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.swiper-slide {
  height: 100vh !important;
}
/* logo */
.container-fluid.logo {
  position: fixed;
  z-index: 999;
  width: 100%;
  text-align: center;
  top: 6%;
}
/* homepage */
.homepage {
  /* background-image: url(../Assets/homebg.png); */
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-size: cover;
}
.homepage h2 {
  color: #fff;
  text-transform: uppercase;
  font-size: 39px;
  letter-spacing: 20px;
  margin-bottom: 10px;
}
.homepage p {
  color: #fff;
  text-transform: uppercase;
  font-size: 28px;
  letter-spacing: 12px;
}

/* aboutpage */

.aboutpage {
  /* background-image: url(../Assets/aboutbg.png); */
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-size: cover;
}
.aboutpage h2 {
  text-align: left;
  color: #fff;
  text-transform: capitalize;
  font-size: 42px;
  letter-spacing: 5px;
  padding-bottom: 10px;
}
.aboutpage p {
  text-align: left;
  color: #fff;
  text-transform: capitalize;
  font-size: 16px;
  letter-spacing: 2px;
}
/* Under */
.under {
  /* background-image: url(../Assets/underbg.png); */
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-size: cover;
}
.under .swiper-slide {
  background-color: transparent !important;
}

.under .swiper-pagination-bullet {
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  color: #000;
  opacity: 1;
  background: rgba(0, 0, 0, 0.2);
}

.under .swiper-pagination-bullet-active {
  color: #fff;
  background: #007aff;
}

.myprojects {
  /* display: flex; */
  height: 100vh;
  align-items: center;
  max-width: 100%;
  justify-content: center;
  padding-top: 20%;
}
.myprojects iframe {
  width: 1270px;
}
.image-gallery-slides img {
  /* height: 390px !important; */
  object-fit: cover !important;
}
.image-gallery-swipe h2 {
  color: #fff;
  font-size: 32px;
  font-weight: 500;
  margin-top: -3%;
  background-color: #0000004d;
  position: relative;
}
button.image-gallery-icon.image-gallery-fullscreen-button {
  display: none;
}
.image-gallery-icon {
  display: none;
}
.image-gallery-thumbnails-wrapper {
  padding-top: 1%;
}
.swiper-button-prev:after,
.swiper-button-next:after {
  color: #fff;
  font-size: 38px !important;
  font-weight: 200 !important;
}
.prevslide {
  position: absolute;
  z-index: 999;
  top: 20%;
  left: 51%;
  background: transparent;
  border: unset;
  color: #fff;
}
button.prevslide div {
  transform: rotate(-90deg);
}
.nextslide {
  position: absolute;
  z-index: 999;
  bottom: 15%;
  left: 51%;
  background: transparent;
  border: unset;
  color: #fff;
}
button.nextslide div {
  transform: rotate(90deg);
}
.row.services h3 {
  color: #fff;
  font-size: 22px;
  padding-bottom: 15px;
}
.row.services h2 {
  padding-bottom: 2%;
}
.partners h2 {
  text-align: center !important;
  font-size: 24px !important;
  padding-top: 0%;
  padding-bottom: 2%;
  letter-spacing: 12px !IMPORTANT;
  text-transform: uppercase !important;
}
.partners .col-sm-2 {
  margin-bottom: 2%;
}

/* service page */

.servicepage {
  /* background-image: url(../Assets/services.png); */
  /* height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-size: cover; */
  background-size: cover;
    background-position: center;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.servicepage h2 {
  text-align: left;
  color: #fff;
  text-transform: capitalize;
  font-size: 42px;
  letter-spacing: 5px;
}
.servicepage p {
  text-align: left;
  color: #fff;
  text-transform: capitalize;
  font-size: 18px;
  letter-spacing: 2px;
  width: 75%;
}
.contactpage .col-sm-6 {
  text-align: left !important;
}
.contactpage input {
  width: 100% !important;
  background: transparent;
  border-radius: 0 !important;
  border: 1px solid #bebdbe !important;
  padding: 10px 20px;
  color: #fff !important;
}
.ant-input-number {
  width: 100%;
  background: transparent;
  border-radius: 0 !important;
  border: 1px solid #bebdbe !important;
  padding: 10px 20px;
}
.ant-input-number input {
  border-radius: 0 !important;
  border: unset !important;
  color: #fff !important;
  padding: 0px !important;
}
textarea.ant-input {
  max-width: 100%;
  height: auto;
  background: transparent !important;
  min-height: 160px !important;
  width: 100% !important;
  background: transparent;
  border-radius: 0 !important;
  border: 1px solid #bebdbe !important;
  padding: 10px 20px;
  color: #fff !important;
  font-family: "Montserrat", sans-serif !important;
}
button.ant-btn {
  background: transparent;
  border: 1px solid #bebdbe;
  border-radius: 0;
  width: 190px;
  height: 40px;
  font-size: 16px;
}
button.ant-btn:hover {
  background: transparent !important;
  border: 1px solid #bebdbe;
}
::placeholder {
  color: #fff !important;
  font-family: "Montserrat", sans-serif !important;
}

.contactpage {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-size: cover;
}
.contactpage h2 {
  text-align: left;
  color: #fff;
  text-transform: capitalize;
  font-size: 42px;
  letter-spacing: 5px;
  margin-bottom: 25px;
  padding-top: 10%;
}
.contactpage p {
  text-align: left;
  color: #fff;
  text-transform: capitalize;
  font-size: 18px;
  letter-spacing: 2px;
  width: 75%;
}
.container-fluid.footer {
  position: absolute;
  z-index: 999;
  bottom: 4%;
}
.container-fluid.footer li {
  color: #fff !important;
  text-transform: uppercase;
  cursor: pointer;
  letter-spacing: 4px;
}
.container-fluid.footer ul {
  display: flex;
  gap: 4%;
  justify-content: center;
  list-style: none;
}
.row.preloader {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000;
}

.row.preloader h2 {
  color: #fff !important;
  font-size: 24px;
  padding-top: 20px;
  font-weight: 500;
  letter-spacing: 5px;
}
.row.preloader img {
  width: 8%;
}
.imagepre.col-sm-12 {
  text-align: center;
}
::-webkit-scrollbar {
  width: 0px !important;
  height: 0 !important;
}
.row.services h3 {
  text-align: left;
  font-size: 19px;
  margin-bottom: 25px;
}
.brands.row {
  width: 80%;
  margin: auto;
  margin-top: 25px;
}
.mobilebrands {
  display: none;
}
.mobilebrands .swiper-slide {
  background: transparent !important;
}
.mobilemenu {
  display: none;
}
.brands.row.desktop img {
  width: 150px !important;
  object-fit: contain;
  height: 70px;
}
nav.image-gallery-thumbnails-container img {
  height: 60px;
}
.under .swiper-button-next {
  right: 10%;
  left: auto;
}
.under .swiper-button-prev {
  left: 10%;
  right: auto;
}
.mySwiper {
  transition: transform 1s ease; /* Adjust the duration as needed */
}

.ant-skeleton.ant-skeleton-active .ant-skeleton-title,
.ant-skeleton.ant-skeleton-active .ant-skeleton-paragraph > li {
  background: linear-gradient(
    90deg,
    rgb(203 187 187 / 84%) 25%,
    rgba(245, 245, 245, 0.74) 37%,
    rgb(251 251 251 / 50%) 63%
  );
  background-size: 400% 100%;
  animation-name: css-dev-only-do-not-override-190m0jy-ant-skeleton-loading;
  animation-duration: 1.4s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
}
/* .home{
  background-image: url('../Assets/homebg.png');
} */
.image-gallery-slides h2 {
  background: #0000006b;
  color: #fff !important;
  text-transform: uppercase;
  margin-top: -3%;
  font-weight: 300;
  letter-spacing: 10px;
  font-size: 18px;
  padding: 8px;
}
.image-gallery-slides{
  position: relative;
}
/* .servicepage{
  background-image: url("../Assets/services.png");
} */
.image-gallery-slides video {
  height: 390px !important;
  object-fit: cover !important;
  width: 100%;
}
.under .disabled{
  display: none;
}
li.react-reveal.active {
  position: relative;
  bottom: 0px;
  padding: 5px 10px;
  color: #fff !important;
  border-radius: 9px;
  transition: all 300ms ease-in-out;
}
li.react-reveal:hover {
  position: relative;
  bottom: 5px;
  padding: 5px 10px;
  color: #fff !important;
  border-radius: 9px;
  transition: all 300ms ease-in-out;
}
li.react-reveal {
  position: relative;
  bottom: 0px;
  padding: 5px 10px;
  border-radius: 9px;
  transition: all 300ms ease-in-out;
}
.sliderSectionImage{
  height: 390px;
}
.ant-image {
  position: relative;
  display: contents !important;
}
.ant-image img {
  height: 420px !important;
}
.ant-image-preview-body {
  background: #0000008f;
}
.spinloads svg{
  fill: #fff !important;
}
.spinloads svg path{
  fill: #fff !important;
}
.spinloadss {
  width: 100% !important;
  height: 100%;
  background: #00000073;
  display: flex;
  justify-content: center;
  align-items: center;
}
.spinloadss i{
  background-color: #fff !important;
}
.spinloadss span{
  color: #fff !important;
}
.preloader h2 {
  text-transform: uppercase !important;
  letter-spacing: 8px !important;
}
.servicepage .container-fluid .container{
  padding: 0 !important;
}
.servicepage .container-fluid .react-reveal.col-sm-2 {
  padding: 0;
}
span.more {
  position: absolute;
  font-size: 12px;
  color: #fff;
  bottom: -50%;
  width: 100px;
}
.swiper-slide.swiper-slide-active{
  overflow-y: hidden !important;
}
.aboutpage .col-sm-12{
  width: 50%;
  background-color: #00000073;
  padding: 10px;
}
.aboutpage.contactpage .col-sm-12 {
  width: 100%;
  background-color:transparent;
  padding: 0px;
}
.swiper-slide{
  background-size: cover !important;
}
.ant-form-item-explain-error{
  color: red !important;
 }